import APICacheModule from "@/APICacheModule";
import Vue from "vue";
import Vuex from "vuex";
import StoreDisplayModule from "./StoreDisplayModule";
import StoreRequestsModule from "./StoreRequestsModule";
import StoreUserModule from "./StoreUserModule";
import StoreNotificationsModule from "./StoreNotificationsModule";
import StoreSelectionsModule from "./StoreSelectionsModule";
import StoreSettingsModule from "./StoreSettingsModule";
import StoreAlertsModule from "./StoreAlertsModule";
import StoreExplorerModule from "./StoreExplorerModule";
import StoreTableSettingsModule from "./StoreTableSettingsModule";
import StorePosthogModule from "./StorePosthogModule";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    apiCache: APICacheModule,
    display: StoreDisplayModule,
    requests: StoreRequestsModule,
    user: StoreUserModule,
    notifications: StoreNotificationsModule,
    selections: StoreSelectionsModule,
    settings: StoreSettingsModule,
    alerts: StoreAlertsModule,
    explorer: StoreExplorerModule,
    tableSettings: StoreTableSettingsModule,
    posthog: StorePosthogModule,
  },
});

/**
 * Stores the  module states ("user", "tableSettings") in local storage.
 * - Retrieved by the initialiseTheStore action in beforeCreate() hook on load.
 * - Would be advantageous to store the entire state, but not possible due to size limitations.
 * - When required, we could add more modules.
 * Reference: https://vuex.vuejs.org/api/#subscribe
 */
store.subscribe((mutation, state) => {
  if (mutation.type.startsWith("user/CHANGE_GROUP")) {
    localStorage.setItem("user_group", JSON.stringify(mutation.payload));
  }

  if (mutation.type.startsWith("tableSettings/SET_INDIVIDUAL_TABLE_SETTINGS")) {
    try{
      localStorage.setItem("tableSettings", JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tableSettings")) || {}),
        [mutation.payload?.tableSettingsKeyName]: mutation.payload,
      }))
    } catch(err) {
      console.error(err)
    }
  }
});

export default store;

/* eslint-enable */
